import React from "react"
import { Row, Spinner, Col } from "reactstrap"

function NOData({ data, loading }) {
  console.log(loading);
  
  return (
    <>
      {/* *********************** Not found****************************** */}
      {loading ? (
        <div className="d-flex justify-content-center ">
        
          <div className="mt-1">
            <Spinner
              type="border"
              className="ms-2 "
              size="sm"
              color="primary"
            />
  
          </div>
          <h5 className="ms-2">Loading...</h5>
        </div>
      ) : (
        data.length === 0 && (
          <Row>
            <div
              className="d-flex justify-content-center p-3"
              style={{
                backgroundColor: "#fff",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              No Data Found !!
            </div>
          </Row>
        )
      )}
    </>
  )
}

export default NOData
