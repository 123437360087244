import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import Switch from "react-switch"

import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import LoadingInsider from "common/LoadingInsider"
import AddNewRDDRReport from "./AddNewRD-DRReport"
import ViewRDDRReport from "./ViewRD-DRReport"
import FilterRDDR from "./FilterRD-DR"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import { usePrevious } from "common/CustomHooks"
import NOData from "common/NOData"

const RDDRReports = () => {
  const history = useHistory()
  const [loading,setLoading]=useState(true)


  //Manged Approved
  const [menu, setMenu] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }

  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)
  const [allReportsChecked, setAllReportsChecked] = useState(false)
  const [isManager, setIsManager] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  //******************************** New Report Toggle********************************//
  const [newReportModal, setNewReportModal] = useState(false)
  const newReportToggle = () => {
    setNewReportModal(!newReportModal)
  }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    referenceNo: [],
    resPerson: [],
    region: [],
    cases: [],
    degree: [],
    reportType: "",
    areaFrom: "",
    areaTo: "",
    costFrom: "",
    costTo: "",
    startDate: "",
    endDate: "",
    lat: "",
    long: "",
    rad: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  const prevFilteredValues = usePrevious(filteredValues);

  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
            setPage(1)
    }

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Type: "DR",
      Page: page,

      ReferenceNo: filteredValues.referenceNo.join(","),
      UserID: filteredValues.resPerson.map(item => item.value).join(","),
      Region: filteredValues.region.map(item => item.value).join(","),
      Cases: filteredValues.cases.map(item => item.value).join(","),
      Degree: filteredValues.degree.map(item => item.value).join(","),
      ReportType: filteredValues.reportType?.value,

      isManager: false,

      AreaFrom: "",
      AreaTo: "",
      CostFrom: "",
      CostTo: "",

      Lat: "",
      Long: "",
      Rad: "",

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }

    if (
      filteredValues.costFrom * 1 >= 0 &&
      filteredValues.costTo * 1 >= 0 &&
      filteredValues.costTo * 1 >= filteredValues.costFrom * 1
    ) {
      formData.CostFrom = filteredValues.costFrom
      formData.CostTo = filteredValues.costTo
    }

    if (
      filteredValues.areaFrom * 1 >= 0 &&
      filteredValues.areaTo * 1 >= 0 &&
      filteredValues.areaTo * 1 >= filteredValues.areaFrom * 1
    ) {
      formData.AreaFrom = filteredValues.areaFrom
      formData.AreaTo = filteredValues.areaTo
    }

    if (filteredValues.lat && filteredValues.long && filteredValues.rad) {
      formData.Lat = filteredValues.lat
      formData.Long = filteredValues.long
      formData.Rad = filteredValues.rad
    }

    if (allReportsChecked) {
      formData.isManager = true
    }
    // console.log("formData", formData)
    axios
      .post("https://rd0.cpvarabia.com/api/Reports/Report_List.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        setIsManager(res.data.ISManger)
        array.splice(-5)
        setReportData(array)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues, allReportsChecked])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="RD-DR Reports" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex col-12">
            <FilterRDDR
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />
            {/* Manged Approve */}
            {/* <Dropdown
              isOpen={menu}
              style={{ height: "40px" }}
              className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
            >
              <DropdownToggle
                className="btn header-item  "
                id="page-header-user-dropdown"
                tag="button"
                style={{ height: "15px" }}
              >
                <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                  Auto Approved
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </div>

          <div className="d-flex justify-content-end">
            {isManager && (
              <div className="d-flex justify-content-end align-items-center mb-2 mx-4">
                <Label
                  htmlFor="material-switch"
                  className="mt-1"
                  style={{ fontSize: "14px" }}
                >
                  All Reports
                </Label>
                <Switch
                  onChange={() => setAllReportsChecked(!allReportsChecked)}
                  checked={allReportsChecked}
                  onColor="#24b571"
                  // onColor="#4458b8"
                  // uncheckedIcon={false}
                  // checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  height={20}
                  width={42}
                  className="react-switch ms-2"
                  id="material-switch"
                />
              </div>
            )}

            <button className="btn btn-primary" onClick={newReportToggle}>
              Add New Report
            </button>
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}

                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "20px" }}>
                          #
                        </th>
                        <th scope="col">Report Id</th>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Res. Person</th>
                        <th scope="col">Rev</th>
                        <th scope="col">Max Degree</th>
                        <th scope="col">Type</th>
                        <th scope="col">Cases</th>
                        <th scope="col">Date</th>
                        <th scope="col">Designer</th>
                        <th scope="col">Contractor</th>
                        <th scope="col">Soil Report</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {reportData.map((item, key) => (
                        <tr key={key}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-check-input  p-1 border-primary"
                            />
                          </td>

                          <td>{item.SPID}</td>

                          <td>{item.ReferenceNo}</td>

                          <td>{item.UserName}</td>

                          <td>
                            {item.Rev?.length === 1 ? `0${item.Rev}` : item.Rev}
                          </td>

                          <td>{item.MaxDegree}</td>

                          <td>{item.Rtype}</td>

                          <td>{item.Cases}</td>

                          <td>{item.CreatedAt?.split(" ")[0]}</td>

                          <td id={"DesignerOfficeName" + key}>
                            {item.DesignerOfficeName.slice(0, 12)}
                            <UncontrolledTooltip
                              placement="right"
                              target={"DesignerOfficeName" + key}
                            >
                              {item.DesignerOfficeName}
                            </UncontrolledTooltip>
                          </td>

                          <td id={"Constructor" + key}>
                            {item.ConstructorName.slice(0, 12)}
                            <UncontrolledTooltip
                              placement="right"
                              target={"Constructor" + key}
                            >
                              {item.ConstructorName}
                            </UncontrolledTooltip>
                          </td>

                          <td id={"soil" + key}>
                            {item.Soil.slice(0, 12)}
                            <UncontrolledTooltip
                              placement="right"
                              target={"soil" + key}
                            >
                              {item.Soil}
                            </UncontrolledTooltip>
                          </td>

                          <td>
                            <span
                              className={
                                item.Status == "Approved"
                                  ? "badge bg-success"
                                  : item.Status == "Rejected"
                                  ? "badge bg-danger"
                                  : item.Status == "Pending"
                                  ? "badge bg-warning"
                                  : ""
                              }
                            >
                              {item.Status}
                            </span>
                          </td>

                          <td>
                            <UncontrolledDropdown className="btn-group bg-primary">
                              <button
                                onClick={() => {
                                  setSelectedItem(item)
                                  viewToggle()
                                }}
                                className="btn btn-primary btn-sm dropdown-toggle"
                              >
                                View
                              </button>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

         
                </div>
              </div>
            </Col>
          </Row>
          <NOData data={reportData} loading={loading} />

          {/* ********************* New Report *************************** */}
          {newReportModal && (
            <AddNewRDDRReport
              newReportToggle={newReportToggle}
              userPermissions={userPermissions}
              setAuth={setAuth}
              editing={editing}
              setEditing={setEditing}
              viewToggle={viewToggle}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
            />
          )}

          {/* ********************* View History Report *************************** */}
          {viewModal && (
            <ViewRDDRReport
              viewToggle={viewToggle}
              selectedItem={selectedItem}
              userPermissions={userPermissions}
              setAuth={setAuth}
              editing={editing}
              setEditing={setEditing}
              isManager={isManager}
            />
          )}

          <ApproveMainModal
            approvedModal={approvedModal}
            toggleApproved={toggleApproved}
            type={4}
          />

          {reportData.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RDDRReports
