import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,

} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
// import FilterReports from "./FilterReports"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import AddNewRD7 from "./AddNewRD7"
import ViewRD7Report from "./ViewRD7Report"
import ViewQuotation from "pages/Quotations/RD-7/ViewQuotation"
import SendQuotation from "pages/Quotations/RD-7/SendQuotation"
import LoadingInsider from "common/LoadingInsider"
import RD7Approval from "./RD7Approval"
import RD7History from "./RD7History"
import RD7Summary from "./RD7Summary"
import FilterRD7 from "./FilterRD7"
import Malath from "./Malath"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import { usePrevious } from "common/CustomHooks"
import TransferRD7Report from "./TransferRD7Report"
import NOData from "common/NOData"


const RD7Reports = () => {
  const history = useHistory()

   //Manged Approved
   const [menu, setMenu] = useState(false)
   const [approvedModal, setapprovedModal] = useState(false)
   const toggleApproved = () => setapprovedModal(!approvedModal)
   const [checked, setChecked] = useState(false)
   const handleChange = () => {
     setChecked(!checked)
   }

  const [malathModal, setMalathModal] = useState(false)
  const toggleMalath = () => {
    setMalathModal(!malathModal)
  }

  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)
  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** Add New Toggle********************************//
  const [newReportModal, setNewReportModal] = useState(false)
  const newReportToggle = () => {
    setNewReportModal(!newReportModal)
  }

  //******************************** View Report Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  //******************************** Approval Toggle ********************************//
  const [approvalModal, setApprovalModal] = useState(false)
  const approvalToggle = () => {
    setApprovalModal(!approvalModal)
  }

  //******************************** History Toggle ********************************//
  const [historyModal, setHistoryModal] = useState(false)
  const historyToggle = () => {
    setHistoryModal(!historyModal)
  }

  //******************************** Summary Toggle ********************************//
  const [summaryModal, setSummaryModal] = useState(false)
  const summaryToggle = () => {
    setSummaryModal(!summaryModal)
  }

  //******************************** View Quotation Toggle********************************//
  const [viewQuotationModal, setViewQuotationModal] = useState(false)
  const viewQuotationToggle = () => {
    setViewQuotationModal(!viewQuotationModal)
  }

  //******************************** Send Quotation Toggle********************************//
  const [sendQuotationModal, setSendQuotationModal] = useState(false)
  const [type, setType] = useState("")
  const sendQuotationToggle = () => {
    setSendQuotationModal(!sendQuotationModal)
  }
    //******************************** Transfer Report Toggle********************************//
    const [transferReportModal, setTransferReportModal] = useState(false)
    const transferReportToggle = () => {
      setTransferReportModal(!transferReportModal)
    }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    reportID: [],
    referenceNo: [],
    category: [],
    startDate: "",
    endDate: "",
    inspector: [],
    RD7Status: [],
    quotationStatus: "",
    TISPaymentStatus: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  const prevFilteredValues = usePrevious(filteredValues);
  // *********************** Status options *********************
  const [statusOptions, setStatusOptions] = useState([])
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
           setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/RD7Status.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setStatusOptions(array)
      })
      .catch(err => console.log(err))
  }, [])

  const reportStatusHandler = status => {
    let selectedStatus = statusOptions.filter(item => item.RD7SID === status)[0]
    return selectedStatus?.Name || ""
  }
// console.log("statusOptions",statusOptions);
  // ********************** Fetching reports data ****************************
  const [IsAreaManager, setIsAreaManager] = useState("")
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      ReportID: filteredValues.reportID.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      Category: filteredValues.category.map(item => item.value).join(","),

      Inspector: filteredValues.inspector.map(item => item.value).join(","),
      RD7Status: filteredValues.RD7Status.map(item => item.value).join(","),
      TISPaymentStatus: filteredValues.TISPaymentStatus,
      QuotationStatus: filteredValues.quotationStatus,

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }

    // console.log("formData", formData)
    axios
      .post("https://rd0.cpvarabia.com/api/RD7/RD7List.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        setIsAreaManager(res.data.IsAreaManager)
        array.splice(-5)
        setReportData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R1.P !== "1" &&
    userPermissions.R1.P !== "2" &&
    userPermissions.R1.P !== "3" &&
    userPermissions.R1.P !== "4" &&
    userPermissions.R1.G !== "1" &&
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1" &&
    userPermissions.R3.P !== "1" &&
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R3.G !== "1" &&
    userPermissions.R4.P !== "1" &&
    userPermissions.R4.P !== "2" &&
    userPermissions.R4.P !== "3" &&
    userPermissions.R4.P !== "4" &&
    userPermissions.R4.G !== "1" &&
    userPermissions.R21.P !== "1" &&
    userPermissions.R21.P !== "2" &&
    userPermissions.R21.P !== "3" &&
    userPermissions.R21.P !== "4" &&
    userPermissions.R21.G !== "1" &&
    userPermissions.R22.P !== "1" &&
    userPermissions.R22.P !== "2" &&
    userPermissions.R22.P !== "3" &&
    userPermissions.R22.P !== "4" &&
    userPermissions.R22.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reports" breadcrumbItem="RD-7 Reports" />

            {/* ********************** Filter Section ***************************** */}
            <div className="d-flex col-12">
            <FilterRD7
              statusOptions={statusOptions}
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />

               {/* Manged Approve */}
               <Dropdown
              isOpen={menu}
              style={{ height: "40px" }}
              className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
            >
              <DropdownToggle
                className="btn header-item  "
                id="page-header-user-dropdown"
                tag="button"
                style={{ height: "15px" }}
              >
                <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                  Auto Approved
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

</div>

            {/* ********************** Add New Report Section ***************************** */}
            {(userPermissions.R21.P === "2" ||
              userPermissions.R21.P === "3" ||
              userPermissions.R21.P === "4" ||
              userPermissions.R21.G === "1") && (
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" onClick={newReportToggle}>
                  Add New Report
                </button>
              </div>
            )}

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}

                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Report Id</th>
                          <th scope="col">Category</th>
                          <th scope="col">Reference No.</th>
                          <th scope="col">Date</th>
                          <th scope="col">Inspector</th>
                          <th scope="col">Total Cost</th>
                          <th scope="col">RD0 Status</th>
                          <th scope="col">Quotation Status</th>
                          <th scope="col">RD7 Status</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {reportData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                // disabled={
                                //   item.Tnum * 1 > 0 ||
                                //   item.Status === "Approved"
                                // }
                                // value={item.PStageID}
                                type="checkbox"
                                // onClick={e => {
                                //   console.log("---------->", e.target.value)
                                //   selectedReportsHandler(e.target.value)
                                // }}
                                className={
                                  "form-check-input  p-1 border-primary "
                                }
                              />
                            </td>

                            <td>
                              {item.RD7ID}{" "}
                              {item.AccurTest === "1" ? (
                                <i className="bx bxs-sun" />
                              ) : (
                                ""
                              )}
                            </td>

                            <td>
                              <i
                                className={
                                  item.Category === "Residential"
                                    ? "fas fa-home"
                                    : item.Category === "Hotels"
                                    ? "fas fa-hotel"
                                    : item.Category === "less than 23 meters"
                                    ? "fas fa-building"
                                    : item.Category === "Commercial Centers"
                                    ? "fas fa-store"
                                    : item.Category === "Entertainment Services"
                                    ? "fas fa-skating"
                                    : item.Category === "High Rise Towers"
                                    ? "fas fa-building"
                                    : item.Category === "High risk buildings"
                                    ? "fas fa-building"
                                    : item.Category === "Motels"
                                    ? "fas fa-hotel"
                                    : item.Category === "Educational"
                                    ? "fas fa-school"
                                    : item.Category ===
                                      "Gathering buildings Mosques"
                                    ? "fas fa-mosque"
                                    : item.Category ===
                                      "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                                    ? "fas fa-building"
                                    : item.Category ===
                                      "Gathering buildings Sports Premises"
                                    ? "fas fa-running"
                                    : item.Category === "Warehouses"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Industrial"
                                    ? "fas fa-industry"
                                    : item.Category ===
                                      "Furnished Hotel Apartments"
                                    ? "fas fa-hotel"
                                    : item.Category ===
                                      "Gathering buildings Wedding Halls Cinemas Theaters"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Hospitals"
                                    ? "fas fa-hospital-alt"
                                    : item.Category === "Healthcare Centers"
                                    ? "fas fa-hospital"
                                    : item.Category ===
                                      "Telecommunication Towers"
                                    ? "fas fa-broadcast-tower"
                                    : "fas fa-building"
                                }
                                id="categorytooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="categorytooltip"
                              >
                                {item.Category || "N/A"}
                              </UncontrolledTooltip>
                            </td>

                            <td>{item.ReferenceNo}</td>

                            <td>{item.CreatedAt}</td>

                            <td>{item.InspectionName}</td>

                            <td>{item.RD7Total}</td>

                            <td>
                              <span>{item.RD0Status}</span>
                            </td>

                            <td>
                              <span
                                className={
                                  item.Paid === "1"
                                    ? "badge bg-success"
                                    : item.Paid === "0"
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.Paid === "1"
                                  ? "Paid"
                                  : item.Paid === "0"
                                  ? "Not Paid"
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span>{reportStatusHandler(item.Status)}</span>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedItem(item)
                                      historyToggle()
                                    }}
                                  >
                                    RD7 History
                                  </DropdownItem>
                                  {(userPermissions.R21.P === "3" ||
                                    userPermissions.R21.P === "4") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        transferReportToggle()
                                      }}
                                    >
                                      Transfer To
                                    </DropdownItem>
                                  )}

                                  {/* {(userPermissions.R21.P === "1" ||
                                    userPermissions.R21.P === "2" ||
                                    userPermissions.R21.P === "3" ||
                                    userPermissions.R21.P === "4" ||
                                    userPermissions.R21.G === "1") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        summaryToggle()
                                      }}
                                    >
                                      RD7 Summary
                                    </DropdownItem>
                                  )} */}

                                  {item.Status === "2" && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        toggleMalath()
                                        setType("assign")
                                      }}
                                    >
                                      Malath assign
                                    </DropdownItem>
                                  )}
                                  {item.Status === "10" && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        toggleMalath()
                                        setType("Approval")
                                      }}
                                    >
                                      Malath Approval
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {/* {reportData.length === 0 && (
                      <LoadingInsider type="spin" color="gray" />
                    )} */}
                  </div>
                </div>
              </Col>
            </Row>
            <NOData data={reportData} />

            {/* ********************* Add New Report *************************** */}
            {newReportModal && (
              <AddNewRD7
                newReportToggle={newReportToggle}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {/* ********************* RD7 Approval *************************** */}
            {/* {approvalModal && (
              <RD7Approval
                approvalToggle={approvalToggle}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )} */}
            {/* ********************* Malath *************************** */}
            {malathModal && (
              <Malath
                malathModal={malathModal}
                toggleMalath={toggleMalath}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                type={type}
                setEditing={setEditing}
              />
            )}

            {/* ********************* RD7 History *************************** */}
            {historyModal && (
              <RD7History
                historyToggle={historyToggle}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {/* ********************* RD7 Summary *************************** */}
            {summaryModal && (
              <RD7Summary
                summaryToggle={summaryToggle}
                selectedItem={selectedItem}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {/* ********************* View Report *************************** */}
            {viewModal && (
              <ViewRD7Report
                viewToggle={viewToggle}
                viewQuotationToggle={viewQuotationToggle}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
                statusOptions={statusOptions}
                IsAreaManager={IsAreaManager}
              />
            )}

            {/* ********************* View Quotation *************************** */}
            {viewQuotationModal && (
              <ViewQuotation
                viewQuotationToggle={viewQuotationToggle}
                sendQuotationToggle={sendQuotationToggle}
                selectedItem={selectedItem}
              />
            )}
              {/* ********************* Transfer Report *************************** */}
              {transferReportModal && (
              <TransferRD7Report
                transferReportToggle={transferReportToggle}
                selectedItem={selectedItem}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {/* ********************* Send Quotation *************************** */}
            {sendQuotationModal && (
              <SendQuotation
                sendQuotationToggle={sendQuotationToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {reportData.length >= 1 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
               <ApproveMainModal
            approvedModal={approvedModal}
            toggleApproved={toggleApproved}
            type={7}
          />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RD7Reports
