import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Dropdown,
  FormGroup,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import FilterReports from "./FilterReports"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ViewRD0Report from "./viewReport"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import Switch from "react-switch"
import { usePrevious } from "common/CustomHooks"
import NOData from "common/NOData"

const Reports = () => {
  const history = useHistory()
    const [loading,setLoading]=useState(true)
  
  //Manged Approved
  const [menu, setMenu] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }

  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    ReferenceNo: "",
    Date: "",
    User: "",
    Type: "",
    Status: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)
  const prevFilteredValues = usePrevious(filteredValues);
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Page: page,
      Ref: filteredValues.ReferenceNo,
      Date: filteredValues.Date,
      Status: filteredValues.Status,
    }

    if (userPermissions.R3.G === "1") {
      axios
        .post("https://rd0.cpvarabia.com/api/RD0View", {
          ...formData,
          UserID: filteredValues.User.split(" - ")[1] || "",
        })
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          let array = Object.values(res.data)
          setTotalPages(res.data.TotalPages)
          array.splice(-4)
          setReportData(array)
          setLoading(false)
          
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      axios
        .post("https://rd0.cpvarabia.com/api/RD0View", {
          ...formData,
          UserID: localStorage.getItem("id"),
        })
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          let array = Object.values(res.data)
          setTotalPages(res.data.TotalPages)
          array.splice(-4)
          setReportData(array)
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [editing, page, filteredValues])

  // ******************* selected Reports ************************
  const [selectedReports, setSelectedReports] = useState([])
  const [selectedReportsStatus, setSelectedReportsStatus] = useState("")
  // console.log("selectedReports==========>", selectedReports)
  // console.log("selectedReportsStatus==========>", selectedReportsStatus)

  const selectedReportsHandler = id => {
    if (selectedReports.includes(id)) {
      setSelectedReports(selectedReports.filter(item => item !== id))
    } else {
      setSelectedReports([...selectedReports, id])
    }
  }

  const errors = { selectedReports: "", selectedStatus: "" }
  selectedReports.map((item, i) => {
    const report = reportData.filter(report => report.PStageID === item)[0]
    // console.log("report", report)
    if (report.Tnum * 1 > 0)
      errors.selectedReports = "some selected reports still have tickets!!"
  })

  if (selectedReports.length > 0 && !selectedReportsStatus)
    errors.selectedStatus = "required status"

  // console.log("errors", errors)

  const submitStatusHandler = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: selectedReports.join(","),
      Status: selectedReportsStatus,
    }

    // console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/UpdateReportStatus", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))
  }
  
  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Manual", label: "Manual" },
    { value: "InProgress", label: "InProgress" },
    { value: "Out-Of-RDAPP", label: "Out-Of-RDAPP" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Under Review", label: "Under Review" },
    { value: "Edit Required", label: "Edit Required" },
    { value: "Manager Approval", label: "Manager Approval" },
  ]

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R3.P !== "1" &&
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R3.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reports" breadcrumbItem="RD-0 Reports" />

            {/* ********************** Filter Section ***************************** */}
            <div className="d-flex col-12">
              <FilterReports
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
              {/* Manged Approved */}
              <Dropdown
                isOpen={menu}
                toggle={() => {}}
                style={{ height: "40px" }}
                className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
              >
                <DropdownToggle
                  className="btn header-item  "
                  id="page-header-user-dropdown"
                  tag="button"
                  style={{ height: "15px" }}
                >
                  <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                    Auto Approved
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Stage Id</th>
                          <th scope="col">Reference No.</th>
                          <th scope="col">Date</th>
                          <th scope="col">Created By</th>
                          {/* <th scope="col">Type</th> */}
                          <th scope="col">Rev</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {reportData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                disabled={
                                  item.Tnum * 1 > 0 ||
                                  item.Status === "Approved"
                                }
                                value={item.PStageID}
                                type="checkbox"
                                onClick={e => {
                                  console.log("---------->", e.target.value)
                                  selectedReportsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>{item.PStageID}</td>

                            <td>{item.ReferenceNo}</td>

                            <td>{item.StageDate}</td>

                            <td>
                              {item.FullName} - {item.InspectorID}
                            </td>
                            <td>
                              {item.Rev} 
                            </td>

                            {/* <td>{item.StageName}</td> */}

                            <td>
                              <span
                                className={
                                  item.Status == "Manual" ||
                                  item.Status == "OutOfRD0"
                                    ? "badge bg-primary"
                                    : item.Status == "Approved"
                                    ? "badge bg-success"
                                    : item.Status == "Rejected" ||
                                      item.Status == "Missing Data"
                                    ? "badge bg-danger"
                                    : item.Status == "Under Review"
                                    ? "badge bg-warning"
                                    : item.Status == "Edit Required"
                                    ? "badge bg-dark"
                                    : "badge bg-info"
                                }
                              >
                                {item.Status}
                              </span>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            <NOData data={reportData} loading={loading} />

            {/**************** Checked Reports Action ****************/}
            {reportData.length > 0 && (
              <Row className="my-4">
                <Col
                  sm={3}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <Label htmlFor="reports-status">
                    CHANGE STATUS OF SELECTED REPORTS
                  </Label>
                  {errors.selectedReports && (
                    <span className="error mx-1 d-flex ">
                      {errors.selectedReports}
                    </span>
                  )}
                </Col>
                <Col sm={2}>
                  <Input
                    disabled={
                      selectedReports.length < 1 ||
                      errors.selectedReports ||
                      userPermissions.R3.G !== "1"
                    }
                    type="select"
                    name="reports-status"
                    id="reports-status"
                    onChange={e => {
                      // console.log("e", e.target.value)
                      setSelectedReportsStatus(e.target.value)
                    }}
                    className="form-control border-dark"
                  >
                    <option value="">Report Status ...</option>
                    {statusOptions.map((status, i) => (
                      <option key={i} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Input>
                  {errors.selectedStatus && (
                    <span className="error mx-1 d-flex ">
                      {errors.selectedStatus}
                    </span>
                  )}
                </Col>
                <Col sm={1}>
                  <button
                    disabled={
                      selectedReports.length < 1 ||
                      errors.selectedReports ||
                      errors.selectedStatus ||
                      userPermissions.R3.G !== "1"
                    }
                    className="btn btn-primary"
                    onClick={submitStatusHandler}
                  >
                    Apply
                  </button>
                </Col>
              </Row>
            )}

            {/* ********************* View Report *************************** */}
            {viewModal && (
              <ViewRD0Report
                viewToggle={viewToggle}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {reportData.length > 1 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}

            <ApproveMainModal
              approvedModal={approvedModal}
              toggleApproved={toggleApproved}
              type={0}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Reports
