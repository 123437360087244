import React from "react"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"

function TicketComments({
  CommentEN,
  CommentAR,
  CorrectiveEN,
  CorrectiveAR,
  degree,
  disabled,
  handleChange,
  errors,
 
}) {
  const fourcommentsDegreeIDArray = ["1", "2", "5", "9", "7", "8"]
  const fourcommentsDegreeTextArray = ["RD5", "TR", "RD5 Inspection "]

  errors ? (errors = errors) : (errors = {})

  

  return (
    <>
      {(degree && degree != "4") ? (
        <Row className="mt-4">
          <Row>
            <Col md={12}>
              <FormGroup className="d-flex align-items-center ">
                <Label for="exampleText" className="me-2">
                  CommentEN :
                </Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    name="CommentEN"
                    id="CommentEN"
                    onChange={handleChange}
                    defaultValue={CommentEN}
                    style={{ height: "50px" }}
                    disabled={disabled}
                  />
                </Col>
                {errors["CommentEN"] ? (
                  <span className="error mx-2 d-flex justify-content-end">
                    {errors["CommentEN"]}
                  </span>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="d-flex align-items-center ">
                <Label for="exampleText" className="me-2">
                  CommentAR :
                </Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    name="CommentAR"
                    id="CommentAR"
                    defaultValue={CommentAR}
                    onChange={handleChange}
                    dir="rtl"
                    style={{ height: "50px" }}
                    disabled={disabled}
                  />
                </Col>
                {errors["CommentAR"] ? (
                  <span className="error mx-2 d-flex justify-content-end">
                    {errors["CommentAR"]}
                  </span>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          {(fourcommentsDegreeIDArray.includes(degree) ||
            fourcommentsDegreeTextArray.includes(degree)) && (
            <Row>
              <Col md={12}>
                <FormGroup className="d-flex align-items-center  ">
                  <Label for="exampleText" className="me-2">
                    CorrectiveEN :
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="textarea"
                      name="CorrectiveEN"
                      id="exampleText"
                      onChange={handleChange}
                      defaultValue={CorrectiveEN}
                      style={{ height: "50px" }}
                      disabled={disabled}
                    />
                  </Col>
                  {errors["CorrectiveEN"] ? (
                    <span className="error mx-2 d-flex justify-content-end">
                      {errors[`${"CorrectiveEN"}`]}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="d-flex align-items-center ">
                  <Label for="exampleText" className="me-2">
                    CorrectiveAR :{" "}
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="textarea"
                      name="CorrectiveAR"
                      id="exampleText"
                      onChange={handleChange}
                      defaultValue={CorrectiveAR}
                      dir="rtl"
                      style={{ height: "50px" }}
                      disabled={disabled}
                    />
                  </Col>
                  {errors["CorrectiveAR"] ? (
                    <span className="error mx-2 d-flex justify-content-end">
                      {errors["CorrectiveAR"]}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          )}
        </Row>
      ) : (
        <Row className="mt-3">
          <Col md={12}>
            <FormGroup className="d-flex align-items-center ">
              <Label for="exampleText" className="me-2">
                Description :
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="CommentEN"
                  id="CommentEN"
                  onChange={handleChange}
                  defaultValue={CommentEN}
                  style={{ height: "50px" }}
                  disabled={disabled}
                />
              </Col>
              {errors["CommentEN"] ? (
                <span className="error mx-2 d-flex justify-content-end">
                  {errors["CommentEN"]}
                </span>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  )
}

export default TicketComments
