import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Dropdown, DropdownToggle, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import LocationInputs from "./LocationInputs"
import Switch from "react-switch"
import MultiGroupSelection from "common/GroupSelector"

const FilterInspection = ({
  filteredValues,
  setFilteredValues,
  setAuth,
  userPermissions,
}) => {
  const history = useHistory()

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }

  const classificationOptions = [
    { value: "1", label: "SP" },
    { value: "0", label: "Non-SP" },
    { value: "NT", label: "NT" },
    { value: "FT", label: "FT" },
  ]

  const [users, setUsers] = useState([])
  const [areaList, setAreaList] = useState([])
  const [resultOptions, setResultOptions] = useState([])
  const [stageList, setStageList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/usercolist.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        let array = res.data
        array = array.filter(item => item !== false)
        setUsers(array)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post(
        "https://rd0.cpvarabia.com/api/CostEstimation/RegionList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setAreaList(array.map(item => ({ value: item.RCID, label: item.Name })))
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {
        ...formData,
        Category: "Inspection",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setResultOptions(
          array.map(item => ({ value: item.DegreeID, label: item.Name }))
        )
      })
      .catch(err => console.log(err))

    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/InsStageList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setStageList(
          array.map(item => ({ value: item.StageID, label: item.StageName }))
        )
      })
      .catch(err => console.log(err))

    axios
      .post("https://rd0.cpvarabia.com/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setCategoryList(
          array.map(item => ({ value: item.BCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // ************************ Cost Range Error ******************************
  const errors = {
    lat: "",
    long: "",
    rad: "",
    location: "",
  }
  //   console.log("errors", errors)

  // Checking location inputs errors
  if (filteredValues.lat || filteredValues.long || filteredValues.rad) {
    if (!filteredValues.lat) {
      errors.lat = "required"
      errors.location = "invalid"
    }
    if (!filteredValues.long) {
      errors.long = "required"
      errors.location = "invalid"
    }
    if (!filteredValues.rad) {
      errors.rad = "required"
      errors.location = "invalid"
    }
  }
  // for location toggle
  const [menu, setMenu] = useState(false)

  return (
    <>
      <Row className="mb-3">
        {/* ******************** Reference No *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** Result *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                // isClearable
                options={resultOptions}
                name="result"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.result}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, result: e })
                }}
                classNamePrefix="select"
                placeholder="Result ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Area *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={areaList}
                name="area"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.area}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, area: e })
                }}
                classNamePrefix="select"
                placeholder="Area ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Date Range *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable={true}
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Date ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Stage *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={stageList}
                name="stage"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.stage}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, stage: e })
                }}
                classNamePrefix="select"
                placeholder="Stage ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Inspector *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <MultiGroupSelection
                options={users}
                name="inspector"
                isMulti={true}
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
              />
              {/* <Select
                isMulti
                options={users}
                name="inspector"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.inspector}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, inspector: e })
                }}
                classNamePrefix="select"
                placeholder="Inspector ..."
              /> */}
            </div>
          </div>
        </Col>

        {/* ******************** Location *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Dropdown
                toggle={() => setMenu(!menu)}
                isOpen={menu}
                style={{ height: "40px" }}
                className="my-auto dropdown-toggle me-3 d-flex align-items-center"
              >
                <DropdownToggle
                  tag="a"
                  to="#"
                  className="card-drop d-flex"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Label
                    onClick={() => setMenu(!menu)}
                    className="my-auto"
                    style={{ color: "grey", cursor: "pointer" }}
                  >
                    Location ...
                  </Label>
                </DropdownToggle>
                {/* <DropdownMenu
                  style={{ width: "400px" }}
                  className="dropdown-menu-end"
                >
                  <LocationInputs
                    filteredValues={filteredValues}
                    setFilteredValues={setFilteredValues}
                    errors={errors}
                  />
                </DropdownMenu> */}
              </Dropdown>
            </div>
            {errors.location && (
              <span className="error  mx-3 d-flex justify-content-end">
                {errors.location}
              </span>
            )}
          </div>
        </Col>

        {/* ******************** Classification *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                isClearable
                options={classificationOptions}
                name="classification"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.classification}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, classification: e })
                }}
                classNamePrefix="select"
                placeholder="Classification ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Category *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={categoryList}
                name="category"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.category}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, category: e })
                }}
                classNamePrefix="select"
                placeholder="Category ..."
              />
            </div>
          </div>
        </Col>

        <Col sm={3}></Col>

        <Col sm={3}>
        <Row style={{ width: "100%" }}>
          <Col sm={6}>
          </Col>
          <Col
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              {filteredValues.referenceNo.length > 0 && (
                <>
                  <Label
                    htmlFor="material-switch"
                    className="mt-1 mx-1"
                    style={{ fontSize: "14px" }}
                  >
                    Contracted
                  </Label>
                  <Switch
                    onChange={() => {
                      setFilteredValues({
                        ...filteredValues,
                        contractedReports: !filteredValues.contractedReports,
                      })
                    }}
                    checked={filteredValues.contractedReports}
                    onColor="#24b571"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    height={20}
                    width={42}
                    className="react-switch"
                    id="material-switch"
                  />
                </>
              )}
            </Col>

          </Row>
        </Col>

        <Col sm={3}
          className={`d-flex justify-content-${
            filteredValues.referenceNo.length > 0 ? "between" : "end"
          } align-items-center flex-wrap`}
        >
          <Row style={{ width: "100%" }} >
          <Col
              sm={6}
              className="d-flex justify-content-center align-items-center mt-1 "
            >
              <Label
                htmlFor="material-switch"
                className="mt-2 mx-1"
                style={{ fontSize: "14px" }}
              >
                MOMRA
              </Label>
              <Switch
                disabled={
                  userPermissions.R2.G !== "1" &&
                  userPermissions.R4.G !== "1" &&
                  userPermissions.R30.G !== "1"
                }
                onChange={() => {
                  setFilteredValues({
                    ...filteredValues,
                    Momra: !filteredValues.Momra,
                  })
                }}
                checked={filteredValues.Momra}
                onColor="#24b571"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={20}
                width={42}
                className="react-switch"
                id="material-switch"
              />
            </Col>

            <Col
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Label
                htmlFor="material-switch"
                className="mt-2 mx-1"
                style={{ fontSize: "14px" }}
              >
                Deleted
              </Label>
              <Switch
                disabled={
                  userPermissions.R2.G !== "1" &&
                  userPermissions.R4.G !== "1" &&
                  userPermissions.R30.G !== "1"
                }
                onChange={() => {
                  setFilteredValues({
                    ...filteredValues,
                    deletedReports: !filteredValues.deletedReports,
                  })
                }}
                checked={filteredValues.deletedReports}
                onColor="#24b571"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={20}
                width={42}
                className="react-switch"
                id="material-switch"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default FilterInspection
